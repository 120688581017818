import React, { useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Select,
  Spin,
  Alert,
  Empty,
  Button,
  Drawer,
  Form,
  Modal,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchModules,
  createModule,
  updateModule,
  deleteModule,
} from './modules.api'
import { useParams, useNavigate } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import AddModuleForm from '../Modules/Form/AddModuleForm'

const { Meta } = Card
const { Option } = Select

const ModuleCard = () => {
  const [modulesToShow, setModulesToShow] = useState(6)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentModule, setCurrentModule] = useState(null)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { modules, loading, error } = useSelector((state) => state.modules)
  const { courseId } = useParams()
  const studentId = localStorage.getItem('studentId')
  const navigate = useNavigate()

  const generateRandomGradient = () => {
    const randomAngle = Math.floor(Math.random() * 360)
    const color1 = '#' + Math.floor(Math.random() * 16777215).toString(16)
    const color2 = '#' + Math.floor(Math.random() * 16777215).toString(16)
    return `linear-gradient(${randomAngle}deg, ${color1}, ${color2})`
  }

  useEffect(() => {
    if (courseId) {
      dispatch(fetchModules(courseId))
    }
  }, [dispatch, courseId])

  const handleSelectChange = (value) => {
    setModulesToShow(value)
  }

  const handleCardClick = (moduleId) => {
    navigate(`/courses/${courseId}/modules/${moduleId}`)
  }

  const handleAddModule = (values) => {
    dispatch(createModule({ ...values, course_id: courseId })).then(() => {
      setIsDrawerVisible(false)
      form.resetFields()
      dispatch(fetchModules(courseId))
    })
  }

  const handleEditModule = (values) => {
    if (currentModule) {
      dispatch(
        updateModule({ course_id: courseId, id: currentModule.id, ...values }),
      ).then(() => {
        setIsDrawerVisible(false)
        setIsEditing(false)
        setCurrentModule(null)
        form.resetFields()
        dispatch(fetchModules(courseId))
      })
    }
  }

  const handleEditClick = (e, module) => {
    e.stopPropagation()
    form.setFieldsValue({
      name: module.name,
      description: module.description,
    })
    setCurrentModule(module)
    setIsEditing(true)
    setIsDrawerVisible(true)
  }

  const handleDeleteModule = (moduleId, e) => {
    e.stopPropagation()
    Modal.confirm({
      title: 'Are you sure you want to delete this module?',
      onOk: () => {
        dispatch(deleteModule(moduleId)).then(() => {
          dispatch(fetchModules(courseId))
        })
      },
    })
  }

  if (loading) {
    return <Spin tip="Loading modules..." />
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}
      >
        <h2 style={{ margin: 0 }}>Modules</h2>
        <p style={{ marginLeft: 'auto' }}>
          Show &nbsp;
          <Select
            defaultValue={modulesToShow.toString()}
            onChange={handleSelectChange}
          >
            <Option value="3">3</Option>
            <Option value="6">6</Option>
            <Option value="9">9</Option>
          </Select>
          &nbsp;
          {!studentId && (
            <Button
              type="primary"
              style={{ backgroundColor: '#005d41', color: 'white' }}
              onClick={() => {
                setIsEditing(false)
                setCurrentModule(null)
                form.resetFields()
                setIsDrawerVisible(true)
              }}
            >
              Add Module
            </Button>
          )}
        </p>
      </div>
      {modules?.length > 0 ? (
        <Row gutter={[16, 16]}>
          {modules.slice(0, modulesToShow).map((module) => (
            <Col key={module.id} span={6}>
              <Card
                hoverable
                style={{
                  width: '100%',
                  borderRadius: 20,
                  marginBottom: '20px',
                }}
                cover={
                  <div
                    style={{
                      background: generateRandomGradient(),
                      height: '160px',
                      borderTopRightRadius: 20,
                      borderTopLeftRadius: 20,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                }
                onClick={() => handleCardClick(module.id)}
              >
                <Meta title={module.name} description={module.description} />
                {!studentId && (
                  <>
                    <Button
                      type="primary"
                      style={{
                        marginTop: '10px',
                        marginRight: '10px',
                        backgroundColor: '#005d41',
                        color: 'white',
                      }}
                      onClick={(e) => handleEditClick(e, module)}
                    >
                      Edit
                    </Button>
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      style={{
                        marginTop: '10px',
                        backgroundColor: '#cb0000',
                        color: 'white',
                      }}
                      onClick={(e) => handleDeleteModule(module.id, e)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty description="No modules available" />
      )}
      <Drawer
        title={isEditing ? 'Edit Module' : 'Add New Module'}
        visible={isDrawerVisible}
        onClose={() => {
          setIsDrawerVisible(false)
          setIsEditing(false)
          setCurrentModule(null)
          form.resetFields()
        }}
        footer={null}
        width={600}
      >
        <AddModuleForm
          form={form}
          handleAddModule={handleAddModule}
          handleEditModule={handleEditModule}
          isEditing={isEditing}
        />
      </Drawer>
    </>
  )
}

export default ModuleCard
