import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Collapse, Spin, Alert, Button, Form, message } from 'antd'
import {
  fetchModuleDetails,
  uploadFiles,
  provideAssignmentFeedback,
  deleteFile,
  uploadStudentSubmission,
} from './modules.api'
import AddFileDrawer from './Form/AddFileDrawer'
import PresentationPanel from './PresentationPanel'
import AssignmentModal from './AssignmentModal'
import UploadAssignmentModal from './UploadAssigmentModal'
import FeedbackModal from './FeedbackModal'

const { Panel } = Collapse

const ModuleDetails = () => {
  const { moduleId } = useParams()
  const dispatch = useDispatch()
  const studentId = localStorage.getItem('studentId')
  const staffId = localStorage.getItem('staffId')
  const adminId = localStorage.getItem('adminId')
  const { moduleDetails, loading, error } = useSelector(
    (state) => state.modules,
  )
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null)
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false)
  const [feedbackValue, setFeedbackValue] = useState('')
  const [selectedStudentUploadId, setSelectedStudentUploadId] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    if (moduleId) {
      dispatch(fetchModuleDetails(moduleId, studentId))
    }
  }, [dispatch, moduleId, studentId])

  const handleUploadAssignment = (values) => {
    const fileData = new FormData()
    fileData.append('file_id', selectedAssignmentId)
    fileData.append('file', values.file[0])
    fileData.append('student_id', studentId)
    dispatch(uploadStudentSubmission(fileData)).then(() => {
      setIsUploadModalVisible(false)
      form.resetFields()
      dispatch(fetchModuleDetails(moduleId, studentId))
    })
  }

  const handleFeedbackSubmit = async () => {
    try {
      await dispatch(
        provideAssignmentFeedback(selectedStudentUploadId, feedbackValue),
      )
      setFeedbackModalVisible(false)
      setFeedbackValue('')
      dispatch(fetchModuleDetails(moduleId, studentId))
    } catch (error) {
      message.error('Failed to submit feedback')
    }
  }

  const handleCancelFeedback = () => {
    setFeedbackModalVisible(false)
    setFeedbackValue('')
  }

  const handleAddFile = async (
    values,
    moduleId,
    dispatch,
    uploadFiles,
    fetchModuleDetails,
  ) => {
    try {
      // Create a new FormData object to handle file upload
      const file = values.file[0].originFileObj
      let file_data = {
        module_id: moduleId,
        file_type: values.fileType,
      }
      if (staffId) {
        file_data.staff_id = staffId
      } else if (studentId) {
        file_data.student_id = studentId
      } else {
        file_data.admin_id = adminId
      }

      // Upload the file
      const response = await dispatch(uploadFiles({ file_data, file }))

      if (response && response.payload) {
        // Refresh module details after successful upload
        await dispatch(fetchModuleDetails(moduleId))
        message.success('File uploaded successfully')
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      message.error('Failed to upload file')
    }
  }

  if (loading) {
    return <Spin tip="Loading module details..." />
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  const presentations = (moduleDetails?.files || []).filter(
    (file) => file.file_type === 'presentation',
  )
  const assignments = (moduleDetails?.files || []).filter(
    (file) => file.file_type === 'assignment',
  )

  const handleFileDelete = (fileId) => {
    dispatch(deleteFile(fileId)).then(() => {
      dispatch(fetchModuleDetails(moduleId))
    })
  }

  return (
    <div>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}
      >
        <h2 style={{ margin: 0 }}>Module Details</h2>
        {!studentId && (
          <Button
            type="primary"
            style={{
              marginLeft: 'auto',
              backgroundColor: '#005d41',
              color: 'white',
            }}
            onClick={() => setIsDrawerVisible(true)}
          >
            Add Files
          </Button>
        )}
      </div>
      <h4>Name: {moduleDetails?.name}</h4>
      <h4>Description:</h4>
      <div
        style={{ lineHeight: '2em' }}
        dangerouslySetInnerHTML={{ __html: moduleDetails?.description }}
      />
      <br />
      <Collapse
        defaultActiveKey={['1', '2']} /* List all your panel keys here */
      >
        <Panel header="Presentations" key="1">
          <PresentationPanel
            presentations={presentations}
            onDelete={handleFileDelete}
            studentId={studentId}
          />
        </Panel>
        <Panel header="Assignments" key="2">
          <AssignmentModal
            studentId={studentId}
            onDelete={handleFileDelete}
            assignments={assignments}
            handleUploadAssignment={handleUploadAssignment}
            setSelectedAssignmentId={setSelectedAssignmentId}
            setIsUploadModalVisible={setIsUploadModalVisible}
            setFeedbackModalVisible={setFeedbackModalVisible}
            setFeedbackValue={setFeedbackValue}
            setSelectedStudentUploadId={setSelectedStudentUploadId}
          />
        </Panel>
      </Collapse>
      <AddFileDrawer
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
        form={form}
        handleAddFile={(values) =>
          // eslint-disable-next-line no-undef
          handleAddFile(
            values,
            moduleId,
            dispatch,
            uploadFiles,
            fetchModuleDetails,
          )
        }
      />
      <UploadAssignmentModal
        isUploadModalVisible={isUploadModalVisible}
        setIsUploadModalVisible={setIsUploadModalVisible}
        form={form}
        handleUploadAssignment={handleUploadAssignment}
      />
      <FeedbackModal
        feedbackModalVisible={feedbackModalVisible}
        handleCancel={handleCancelFeedback}
        feedbackValue={feedbackValue}
        setFeedbackValue={setFeedbackValue}
        handleFeedbackSubmit={handleFeedbackSubmit}
        Id={studentId}
      />
    </div>
  )
}

export default ModuleDetails
