import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button } from 'antd'

const AddModuleForm = ({
  form,
  handleAddModule,
  handleEditModule,
  isEditing,
}) => {
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={isEditing ? handleEditModule : handleAddModule}
    >
      <Form.Item
        name="name"
        label="Module Name"
        rules={[{ required: true, message: 'Please enter the module name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Please enter the description' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          style={{ backgroundColor: '#005d41', color: 'white' }}
          type="primary"
          htmlType="submit"
        >
          {isEditing ? 'Update Module' : 'Add Module'}
        </Button>
      </Form.Item>
    </Form>
  )
}

AddModuleForm.propTypes = {
  form: PropTypes.object.isRequired,
  handleAddModule: PropTypes.func.isRequired,
  handleEditModule: PropTypes.func,
  isEditing: PropTypes.bool.isRequired,
}

AddModuleForm.defaultProps = {
  handleEditModule: () => {},
}

export default AddModuleForm
