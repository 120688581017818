import React, { useEffect, useState } from 'react'
import { Table, Spin, Alert, Button, Space, Drawer, Form, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchStudents,
  createStudent,
  updateStudent,
  deleteStudent,
} from './students.api'
import AddStudentForm from './Form/AddStudentForm'

const Students = () => {
  const dispatch = useDispatch()
  const { loading, students, error } = useSelector((state) => state.students)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentStudent, setCurrentStudent] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(fetchStudents())
  }, [dispatch])

  const handleDelete = (id) => {
    dispatch(deleteStudent(id)).then(() => {
      dispatch(fetchStudents())
    })
  }

  const handleAddStudent = (values) => {
    if (values.dob) {
      values.dob = values.dob.format('YYYY-MM-DD')
    }
    if (values.special_need_required === 'yes') {
      values.special_need_required = false
    } else {
      values.special_need_required = true
    }
    dispatch(createStudent(values)).then(() => {
      setDrawerVisible(false)
      form.resetFields()
      dispatch(fetchStudents())
    })
  }

  const handleEditStudent = (values) => {
    if (currentStudent) {
      dispatch(updateStudent({ id: currentStudent.id, values })).then(() => {
        setDrawerVisible(false)
        setIsEditing(false)
        setCurrentStudent(null)
        form.resetFields()
        dispatch(fetchStudents())
      })
    }
  }

  const handleEditClick = (student) => {
    setCurrentStudent(student)
    form.setFieldsValue({
      first_name: student.first_name,
      last_name: student.last_name,
      email: student.email,
    })
    setIsEditing(true)
    setDrawerVisible(true)
  }

  // Truncate long content with ellipsis and show full content on hover
  const truncateText = (text, maxLength = 50) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`
    }
    return text
  }

  const getIntakeInfo = (intakes) => {
    if (!intakes || intakes.length === 0) return 'N/A'
    const intakeText = intakes
      .map((intake) => `${intake.name} (${intake.campus?.name})`)
      .join(', ')

    return (
      <Tooltip title={intakeText}>
        {truncateText(intakeText, 50)} {/* Truncate to 30 characters */}
      </Tooltip>
    )
  }

  const columns = [
    { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Intake',
      key: 'intake',
      render: (_, record) => getIntakeInfo(record.intakes),
    },
    {
      title: 'Date of Birth',
      key: 'dob',
      dataIndex: 'dob',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{
              backgroundColor: '#005d41',
              color: 'white',
            }}
            type="primary"
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            style={{ backgroundColor: '#cb0000', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  if (loading) {
    return <Spin tip="Loading students..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Students</h2>
        <Button
          type="primary"
          style={{
            backgroundColor: '#005d41',
            color: 'white',
          }}
          onClick={() => {
            setIsEditing(false)
            setCurrentStudent(null)
            form.resetFields()
            setDrawerVisible(true)
          }}
        >
          Add Student
        </Button>
      </div>
      <Table columns={columns} dataSource={students} rowKey="id" />

      <Drawer
        title={isEditing ? 'Edit Student' : 'Add Student'}
        width={700}
        onClose={() => {
          setDrawerVisible(false)
          setIsEditing(false)
          setCurrentStudent(null)
          form.resetFields()
        }}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddStudentForm
          onSubmit={isEditing ? handleEditStudent : handleAddStudent}
          form={form}
        />
      </Drawer>
    </div>
  )
}

export default Students
