import React, { useEffect, useState } from 'react'
import {
  Table,
  Spin,
  Alert,
  Button,
  Space,
  Drawer,
  Statistic,
  Card,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchIntakes, deleteIntake, fetchStudentIntakes } from './intakes.api'
import { format } from 'date-fns'
import AddIntakePage from './Form/AddIntakePage'

const Intakes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, intakes, error } = useSelector((state) => state.intakes)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [hoveredRowId, setHoveredRowId] = useState(null) // Local state for hover
  const { studentId } = useParams()
  const [editMode, setEditMode] = useState(false)
  const [selectedIntake, setSelectedIntake] = useState(null)

  const handleEdit = (intake) => {
    setSelectedIntake(intake)
    setDrawerVisible(true)
    setEditMode(true)
  }

  useEffect(() => {
    if (!studentId) {
      dispatch(fetchIntakes())
    }
  }, [dispatch])

  const handleDelete = (id) => {
    dispatch(deleteIntake(id))
  }

  useEffect(() => {
    if (studentId) {
      dispatch(fetchStudentIntakes(studentId))
    }
  }, [dispatch, studentId])

  const columns = [
    {
      title: 'Intakes Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/intakes/intakeDetails/${record.id}`)}
          style={{ padding: 0 }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Intake Code',
      dataIndex: 'intake_code',
      key: 'intake_code',
    },
    {
      title: 'Valid Till',
      dataIndex: 'available_till_date',
      key: 'available_till_date',
      render: (date) => format(new Date(date), 'yyyy-MM-dd'),
    },
    { title: 'Campus', dataIndex: ['campus', 'name'], key: 'campus' },
    {
      title: 'Courses',
      dataIndex: 'courses',
      key: 'campus',
      render: (campus) => campus.length,
    },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Capacity', dataIndex: 'capacity', key: 'capacity' },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (date) => format(new Date(date), 'yyyy-MM-dd'),
    },
    !studentId && {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ backgroundColor: '#005d41', color: 'white' }}
            type="primary"
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            type="danger"
            style={{ backgroundColor: '#cb0000', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ].filter(Boolean)

  if (loading) {
    return <Spin tip="Loading intakes..." />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Card>
          <Statistic title="TOTAL" value={intakes.length} />
        </Card>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Intakes</h2>
        {!studentId && (
          <Button
            style={{ backgroundColor: '#005d41', color: 'white' }}
            type="primary"
            onClick={() => setDrawerVisible(true)}
          >
            Add Intake
          </Button>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={intakes}
        rowKey="id"
        onRow={(record) => ({
          onMouseEnter: () => setHoveredRowId(record.id),
          onMouseLeave: () => setHoveredRowId(null),
          style: {
            backgroundColor: hoveredRowId === record.id ? '#e6f7ff' : 'white',
          },
        })}
      />

      <Drawer
        title={editMode ? 'Edit Intake' : 'Add Intake'}
        width={500}
        onClose={() => {
          setDrawerVisible(false)
          setEditMode(false) // Reset the mode when the drawer is closed
        }}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddIntakePage
          setDrawerVisible={() => setDrawerVisible(false)}
          intakeData={selectedIntake}
          editMode={editMode}
        />
      </Drawer>
    </div>
  )
}

export default Intakes
