import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Table, Button, Switch, Spin, Alert, Empty, message, Input } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { fetchSubmissions, toggleAllowUpload } from './modules.api'

const AssignmentSubmissions = () => {
  const { assignmentId } = useParams()
  const dispatch = useDispatch()

  const {
    fetchedSubmissions: submissions,
    loading,
    error,
  } = useSelector((state) => state.modules)

  const [feedbacks, setFeedbacks] = useState({})
  const [allowUploadStates, setAllowUploadStates] = useState({})

  useEffect(() => {
    dispatch(fetchSubmissions(assignmentId))
  }, [dispatch, assignmentId])

  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.download = url.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleSubmitChanges = async (submission_id) => {
    try {
      const allow_revision = allowUploadStates[submission_id] || false
      const feedback = feedbacks[submission_id] || null
      await dispatch(toggleAllowUpload(submission_id, allow_revision, feedback))
      message.success('Feedback and upload status updated successfully')
      dispatch(fetchSubmissions(assignmentId)) // Refresh data after submission
    } catch (error) {
      message.error('Failed to submit feedback and toggle upload status')
    }
  }

  const handleFeedbackChange = (submission_id, feedback) => {
    setFeedbacks({
      ...feedbacks,
      [submission_id]: feedback,
    })
  }

  const handleAllowUploadChange = (submission_id, checked) => {
    setAllowUploadStates({
      ...allowUploadStates,
      [submission_id]: checked,
    })
  }

  const expandedRowRender = (student) => {
    const latestSubmission = student.submissions.reduce(
      (latest, submission) =>
        new Date(submission.submission_time) > new Date(latest.submission_time)
          ? submission
          : latest,
      student.submissions[0],
    )

    const submissionColumns = [
      {
        title: 'File Name',
        dataIndex: 'file_name',
        key: 'file_name',
        render: (_, record) => {
          const fileName = record.submission_file_path.split('/').pop()
          return fileName
        },
      },
      {
        title: 'Download',
        key: 'download',
        render: (_, record) => (
          <Button
            icon={<DownloadOutlined />}
            onClick={() => downloadFile(record.submission_file_path)}
            style={{ backgroundColor: '#005d41', color: 'white' }}
          >
            Download Submission
          </Button>
        ),
      },
      {
        title: 'Allow Upload Again',
        key: 'allow_upload',
        render: (_, record) =>
          record.id === latestSubmission.id && (
            <Switch
              checked={allowUploadStates[record.id] ?? record.allow_revision}
              onChange={(checked) =>
                handleAllowUploadChange(record.id, checked)
              }
            />
          ),
      },
      {
        title: 'Feedback',
        key: 'feedback',
        render: (_, record) =>
          record.id === latestSubmission.id ? (
            <Input
              value={feedbacks[record.id] || record.feedback}
              onChange={(e) => handleFeedbackChange(record.id, e.target.value)}
              placeholder="Enter feedback"
            />
          ) : null, // Do not show for older submissions
      },
      {
        title: 'Submit Changes',
        key: 'submit',
        render: (_, record) =>
          record.id === latestSubmission.id && (
            <Button
              type="primary"
              onClick={() => handleSubmitChanges(record.id)}
              style={{ backgroundColor: '#005d41', color: 'white' }}
            >
              Submit
            </Button>
          ),
      },
    ]

    return (
      <Table
        columns={submissionColumns}
        dataSource={student.submissions}
        rowKey="id"
        pagination={false}
      />
    )
  }

  const columns = [
    {
      title: 'Student Name',
      dataIndex: ['student', 'first_name'],
      key: 'student_name',
      render: (_, record) =>
        `${record.student.first_name} ${record.student.last_name}`,
    },
  ]

  if (loading) {
    return <Spin tip="Loading submissions..." />
  }

  if (!submissions || submissions.length === 0) {
    return <Empty description="No submissions found" />
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    )
  }

  return (
    <div>
      <h2>Submissions</h2>
      <Table
        columns={columns}
        dataSource={submissions}
        rowKey="student.id"
        defaultExpandAllRows
        expandable={{ expandedRowRender }}
        pagination={false}
      />
    </div>
  )
}

export default AssignmentSubmissions
