import React from 'react'
import { Layout, Typography, Row, Col, Card } from 'antd'
import PropTypes from 'prop-types'
import dashboardPic from '../../assets/images/dashboardPic.jpeg'
const { Header, Content } = Layout
const { Paragraph } = Typography

const DashboardView = (props) => {
  const { increment } = props
  return (
    <div
      style={{
        backgroundImage: `url(${dashboardPic})`,
        height: '1200px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(1,48,1,0.5)',
          zIndex: 1,
        }}
      >
        <Header
          style={{
            background: 'transparent',
            display: 'flex',
            color: 'white',
            alignItems: 'center',
            paddingTop: '100px',
          }}
        >
          <h1 style={{ margin: 0 }}>Welcome to Virtual Learning Environment</h1>
        </Header>
        <Content style={{ padding: '50px' }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card title="Courses" onClick={increment}>
                <Paragraph>
                  Here you can find all the courses you are enrolled in.
                  {/*<h1>Counter: {counter}</h1>*/}
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Announcements">
                <Paragraph>
                  Stay up-to-date with the latest announcements from your
                  instructors.
                </Paragraph>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: '30px' }}>
            <Col span={12}>
              <Card title="Assignments">
                <Paragraph>
                  View and submit assignments for your courses.
                </Paragraph>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Grades">
                <Paragraph>
                  Check your grades and feedback from your instructors.
                </Paragraph>
              </Card>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
  )
}

DashboardView.propTypes = {
  counter: PropTypes.number.isRequired,
  increment: PropTypes.func.isRequired,
  // decrement: PropTypes.func.isRequired,
}

export default DashboardView
