import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchStaffsRequest,
  fetchStaffsSuccess,
  fetchStaffsFailure,
  createStaffRequest,
  createStaffSuccess,
  createStaffFailure,
  updateStaffRequest,
  updateStaffSuccess,
  updateStaffFailure,
  deleteStaffRequest,
  deleteStaffSuccess,
  deleteStaffFailure,
  searchStaffsSuccess,
  searchStaffsFailure,
} from './staffs.actions'

// Function to handle fetching staffs
export const fetchStaffs = () => {
  return (dispatch) => {
    dispatch(fetchStaffsRequest())
    return request({
      method: 'GET',
      url: '/staffs/',
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchStaffsSuccess(response.data))
        } else if (response?.status === 204) {
          dispatch(fetchStaffsSuccess([]))
        } else {
          dispatch(fetchStaffsFailure('Fetching staffs failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Admins Failed',
            description: 'Fetching staffs failed',
          })
        }
      })
      .catch((error) => {
        dispatch(fetchStaffsFailure('An error occurred while fetching staffs'))
        openNotification({
          type: 'error',
          title: 'Fetching Admins Error',
          description: `An error occurred while fetching staffs: ${error.message}`,
        })
      })
  }
}

// Function to handle creating a staff
export const createStaff = (staffData) => {
  return (dispatch) => {
    dispatch(createStaffRequest(staffData))
    return request({
      method: 'POST',
      url: '/staffs/',
      data: staffData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createStaffSuccess(response.data))
        } else {
          dispatch(createStaffFailure('Creating staff failed'))
          openNotification({
            type: 'error',
            title: 'Creating Staff Failed',
            description: 'Creating staff failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createStaffFailure('An error occurred while creating the staff'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Staff Error',
          description: `An error occurred while creating the staff: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a staff
export const updateStaff = (staffData) => {
  return (dispatch) => {
    dispatch(updateStaffRequest(staffData))
    return request({
      method: 'PUT',
      url: `/staffs/${staffData.id}`,
      data: staffData.values,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateStaffSuccess(response.data))
        } else {
          dispatch(updateStaffFailure('Updating staff failed'))
          openNotification({
            type: 'error',
            title: 'Updating Staff Failed',
            description: 'Updating staff failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateStaffFailure('An error occurred while updating the staff'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Staff Error',
          description: `An error occurred while updating the staff: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a staff
export const deleteStaff = (staffId) => {
  return (dispatch) => {
    dispatch(deleteStaffRequest(staffId))
    return request({
      method: 'DELETE',
      url: `/staffs/${staffId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteStaffSuccess(staffId))
        } else {
          dispatch(deleteStaffFailure('Deleting staff failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Staff Failed',
            description: 'Deleting staff failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteStaffFailure('An error occurred while deleting the staff'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Staff Error',
          description: `An error occurred while deleting the staff: ${error.message}`,
        })
      })
  }
}

// Function to handle searching students
export const searchStaffs = (query) => {
  return (dispatch) => {
    return request({
      method: 'GET',
      url: `/staffs/?name=${query}/`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(searchStaffsSuccess(response.data))
        } else {
          dispatch(searchStaffsFailure('Searching staff failed'))
          openNotification({
            type: 'error',
            title: 'Searching Admins Failed',
            description: 'Searching staffs failed',
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // Handle 404 (not found) error
          dispatch(searchStaffsFailure('Searching staff failed'))
        } else {
          openNotification({
            type: 'error',
            title: 'Searching Admins Error',
            description: `An error occurred while searching staffs: ${error.message}`,
          })
        }
      })
  }
}
