import React, { useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Select,
  Spin,
  Alert,
  Button,
  Drawer,
  Form,
  Popconfirm,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  fetchCourses,
  createCourse,
  updateCourse,
  deleteCourse,
  fetchStudentCourses,
} from './courses.api'
import AddCourseForm from './Form/AddCourseForm'
import { fetchCampuses } from '../Campuses/campuses.api'
import dayjs from 'dayjs'

const { Meta } = Card
const { Option } = Select

const CourseCard = () => {
  const [coursesToShow, setCoursesToShow] = useState(9)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentCourse, setCurrentCourse] = useState(null)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { courses, loading, error } = useSelector((state) => state.courses)
  const { campuses } = useSelector((state) => state.campuses)
  const navigate = useNavigate()
  const { studentId } = useParams()

  const generateRandomGradient = () => {
    const randomAngle = Math.floor(Math.random() * 360)
    const color1 = '#' + Math.floor(Math.random() * 16777215).toString(16)
    const color2 = '#' + Math.floor(Math.random() * 16777215).toString(16)
    return `linear-gradient(${randomAngle}deg, ${color1}, ${color2})`
  }

  useEffect(() => {
    if (!studentId) {
      dispatch(fetchCourses())
      dispatch(fetchCampuses())
    }
  }, [dispatch])

  useEffect(() => {
    if (studentId) {
      dispatch(fetchStudentCourses(studentId))
    }
  }, [dispatch, studentId])

  const handleSelectChange = (value) => {
    setCoursesToShow(value)
  }

  const handleCardClick = (courseId) => {
    navigate(`/courses/${courseId}/modules`)
  }

  const handleAddCourse = (values) => {
    dispatch(createCourse(values)).then(() => {
      setIsDrawerVisible(false)
      form.resetFields()
      dispatch(fetchCourses())
    })
  }

  const handleEditCourse = (values) => {
    if (currentCourse) {
      dispatch(updateCourse({ id: currentCourse.id, ...values })).then(() => {
        setIsDrawerVisible(false)
        setIsEditing(false)
        setCurrentCourse(null)
        form.resetFields()
        dispatch(fetchCourses())
      })
    }
  }

  const handleEditClick = (e, course) => {
    e.stopPropagation()

    form.setFieldsValue({
      name: course.name,
      description: course.description,
      campus_id: course.campus.id,
      learning_outcome: course.learning_outcome,
      module_outline: course.module_outline,
      start_date: course.start_date ? dayjs(course.start_date) : null,
      end_date: course.end_date ? dayjs(course.end_date) : null,
    })

    setCurrentCourse(course)
    setIsEditing(true)
    setIsDrawerVisible(true)
  }

  const handleDeleteCourse = (courseId, e) => {
    e.stopPropagation()
    dispatch(deleteCourse(courseId)).then(() => {
      dispatch(fetchCourses())
    })
  }

  if (loading) {
    return <Spin tip="Loading courses..." />
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}
      >
        <h2 style={{ margin: 0 }}>Courses</h2>
        <p style={{ marginLeft: 'auto' }}>
          Show &nbsp;
          <Select
            defaultValue={coursesToShow.toString()}
            onChange={handleSelectChange}
          >
            <Option value="3">3</Option>
            <Option value="6">6</Option>
            <Option value="9">9</Option>
          </Select>
          &nbsp;
          {!studentId && (
            <Button
              style={{ backgroundColor: '#005d41', color: 'white' }}
              type="primary"
              onClick={() => {
                setIsEditing(false)
                setCurrentCourse(null)
                form.resetFields()
                setIsDrawerVisible(true)
              }}
            >
              Add Course
            </Button>
          )}
        </p>
      </div>

      {courses.length === 0 ? (
        <Alert
          message="No Courses Available"
          description={
            !studentId
              ? 'There are currently no courses to display. Please add a new course.'
              : 'You are currently associated with any course.'
          }
          type="info"
          showIcon
          style={{ marginBottom: '16px' }}
        />
      ) : (
        <Row gutter={[16, 16]}>
          {courses.slice(0, coursesToShow).map((course) => (
            <Col key={course.id} span={6}>
              <Card
                hoverable
                style={{
                  width: '100%',
                  borderRadius: 20,
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '400px', // Set fixed height for the card
                }}
                cover={
                  <div
                    style={{
                      background: generateRandomGradient(),
                      height: '160px',
                      borderTopRightRadius: 20,
                      borderTopLeftRadius: 20,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                }
                onClick={() => handleCardClick(course.id)}
              >
                <Meta
                  title={<span style={{ fontWeight: 600 }}>{course.name}</span>}
                  description={
                    <>
                      <p>
                        <b>Campus:</b> {course.campus.name}
                      </p>
                      <p
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 3, // Limit to 3 lines
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis', // Add ellipsis
                          height: '60px', // Adjust height for the text
                        }}
                      >
                        <b>Description:</b> {course.description}
                      </p>
                    </>
                  }
                />
                {!studentId && (
                  <>
                    <Button
                      type="primary"
                      style={{
                        marginTop: '10px',
                        marginRight: '10px',
                        backgroundColor: '#005d41',
                        color: 'white',
                      }}
                      onClick={(e) => handleEditClick(e, course)}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title="Are you sure to delete this course?"
                      onConfirm={(e) => handleDeleteCourse(course.id, e)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        danger
                        style={{
                          backgroundColor: '#cb0000',
                          color: 'white',
                          marginTop: '10px',
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </Card>
            </Col>
          ))}
        </Row>
      )}

      <Drawer
        title={isEditing ? 'Edit Course' : 'Add New Course'}
        visible={isDrawerVisible}
        onClose={() => {
          setIsDrawerVisible(false)
          setIsEditing(false)
          setCurrentCourse(null)
          form.resetFields()
        }}
        footer={null}
        width={600}
      >
        <AddCourseForm
          form={form}
          setIsDrawerVisible={setIsDrawerVisible}
          campuses={campuses}
          handleAddCourse={handleAddCourse}
          handleEditCourse={handleEditCourse}
          isEditing={isEditing}
        />
      </Drawer>
    </>
  )
}

export default CourseCard
