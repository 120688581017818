import React from 'react'
import { Form, Input, Button, DatePicker, Select, InputNumber } from 'antd'
import PropTypes from 'prop-types'

const { Option } = Select

const AddIntakeForm = ({
  onSubmit,
  form,
  campuses,
  courses,
  // eslint-disable-next-line react/prop-types
  setDrawerVisible,
}) => (
  <Form layout="vertical" form={form} onFinish={onSubmit}>
    <Form.Item
      name="intake_code"
      label="Intake Code"
      rules={[{ required: true, message: 'Please enter the intake code' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="name"
      label="Intake Name"
      rules={[{ required: true, message: 'Please enter the intake name' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="description"
      label="Description"
      rules={[{ required: true, message: 'Please enter a description' }]}
    >
      <Input.TextArea />
    </Form.Item>
    <Form.Item
      name="available_till_date"
      label="Available Till Date"
      rules={[{ required: true, message: 'Please select the date' }]}
    >
      <DatePicker format="DD/MM/YYYY" />
    </Form.Item>
    <Form.Item
      name="start_date"
      label="Start Date"
      rules={[{ required: true, message: 'Please select the start date' }]}
    >
      <DatePicker format="DD/MM/YYYY" />
    </Form.Item>
    <Form.Item
      name="status"
      label="Status"
      rules={[{ required: true, message: 'Please select a status' }]}
    >
      <Select>
        <Option value="open">Open</Option>
        <Option value="closed">Closed</Option>
        <Option value="pending">Pending</Option>
      </Select>
    </Form.Item>
    <Form.Item
      name="capacity"
      label="Capacity"
      rules={[{ required: true, message: 'Please enter the capacity' }]}
    >
      <InputNumber min={1} />
    </Form.Item>
    <Form.Item
      name="campus_id"
      label="Campus"
      rules={[{ required: true, message: 'Please select a campus' }]}
    >
      <Select>
        {campuses?.length > 0 &&
          campuses.map((campus) => (
            <Option key={campus.id} value={campus.id}>
              {campus.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
    <Form.Item
      name="courses"
      label="Select Courses"
      rules={[{ required: true, message: 'Please select courses' }]}
    >
      <Select mode="multiple">
        {courses?.length > 0 &&
          courses.map((course) => (
            <Option key={course.id} value={course.id}>
              {course.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
    <Form.Item>
      <Button
        style={{ backgroundColor: '#005d41', color: 'white' }}
        type="primary"
        htmlType="submit"
      >
        Submit
      </Button>
      <Button
        style={{ marginLeft: 8 }}
        onClick={() => {
          form.resetFields()
          setDrawerVisible(false)
        }}
      >
        Cancel
      </Button>
    </Form.Item>
  </Form>
)

AddIntakeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  campuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default AddIntakeForm
