import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchCoursesRequest,
  fetchCoursesSuccess,
  fetchCoursesFailure,
  createCourseRequest,
  createCourseSuccess,
  createCourseFailure,
  updateCourseRequest,
  updateCourseSuccess,
  updateCourseFailure,
  deleteCourseRequest,
  deleteCourseSuccess,
  deleteCourseFailure,
  fetchStudentCoursesRequest,
  fetchStudentCoursesSuccess,
  fetchStudentCoursesFailure,
} from './courses.actions'

// Function to handle fetching courses
export const fetchCourses = () => {
  return (dispatch) => {
    dispatch(fetchCoursesRequest())
    return request({
      method: 'GET',
      url: '/courses/',
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchCoursesSuccess(response.data))
        } else if (response.status === 204) {
          dispatch(fetchCoursesSuccess([]))
        } else {
          dispatch(fetchCoursesFailure('Fetching courses failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Courses Failed',
            description: 'Fetching courses failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchCoursesFailure('An error occurred while fetching courses'),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Courses Error',
          description: `An error occurred while fetching courses: ${error.message}`,
        })
      })
  }
}

// Function to handle creating a course
export const createCourse = (courseData) => {
  return (dispatch) => {
    dispatch(createCourseRequest(courseData))
    return request({
      method: 'POST',
      url: '/courses/',
      data: courseData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createCourseSuccess(response.data))
        } else if (response.status === 204) {
          dispatch(createCourseSuccess([]))
        } else {
          dispatch(createCourseFailure('Creating course failed'))
          openNotification({
            type: 'error',
            title: 'Creating Course Failed',
            description: 'Creating course failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createCourseFailure('An error occurred while creating the course'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Course Error',
          description: `An error occurred while creating the course: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a course
export const updateCourse = (courseData) => {
  return (dispatch) => {
    console.log('dsf', courseData)
    dispatch(updateCourseRequest(courseData))
    return request({
      method: 'PUT',
      url: `/courses/${courseData.id}`,
      data: courseData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateCourseSuccess(response.data))
        } else {
          dispatch(updateCourseFailure('Updating course failed'))
          openNotification({
            type: 'error',
            title: 'Updating Course Failed',
            description: 'Updating course failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateCourseFailure('An error occurred while updating the course'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Course Error',
          description: `An error occurred while updating the course: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a course
export const deleteCourse = (courseId) => {
  return (dispatch) => {
    dispatch(deleteCourseRequest(courseId))
    return request({
      method: 'DELETE',
      url: `/courses/${courseId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteCourseSuccess(courseId))
        } else {
          dispatch(deleteCourseFailure('Deleting course failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Course Failed',
            description: 'Deleting course failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteCourseFailure('An error occurred while deleting the course'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Course Error',
          description: `An error occurred while deleting the course: ${error.message}`,
        })
      })
  }
}

export const fetchStudentCourses = (studentId) => {
  return (dispatch) => {
    dispatch(fetchStudentCoursesRequest())
    return request({
      method: 'GET',
      url: `/courses?student_id=${studentId}/`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchStudentCoursesSuccess(response.data))
        } else if (response?.status === 204) {
          dispatch(fetchStudentCoursesSuccess([]))
        } else {
          dispatch(fetchStudentCoursesFailure('Fetching courses failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Courses Failed',
            description: 'Fetching courses failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchStudentCoursesFailure(
            'An error occurred while fetching courses',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Courses Error',
          description: `An error occurred while fetching courses: ${error.message}`,
        })
      })
  }
}
