import React, { useEffect } from 'react'
import { Form, message } from 'antd'
import AddIntakeForm from './AddIntakeForm'
import { fetchCourses } from '../../Courses/courses.api'
import { fetchCampuses } from '../../Campuses/campuses.api'
import { createIntake, updateIntake } from '../intakes.api'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

// eslint-disable-next-line react/prop-types
const AddIntakePage = ({ setDrawerVisible, editMode, intakeData }) => {
  const [form] = Form.useForm()
  const { campuses } = useSelector((state) => state.campuses)
  const { courses } = useSelector((state) => state.courses)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCampuses())
    dispatch(fetchCourses())
  }, [dispatch])

  useEffect(() => {
    if (editMode && intakeData) {
      form.setFieldsValue({
        ...intakeData,
        // eslint-disable-next-line react/prop-types
        available_till_date: dayjs(intakeData.available_till_date),
        // eslint-disable-next-line react/prop-types
        start_date: dayjs(intakeData.start_date),
        // eslint-disable-next-line react/prop-types
        courses: intakeData?.courses?.map((course) => course.id),
      })
    }
  }, [editMode, intakeData, form])

  const handleSubmit = async (values) => {
    try {
      values.available_till_date = dayjs(values.available_till_date).format(
        'YYYY-MM-DD',
      )
      values.start_date = dayjs(values.start_date).format('YYYY-MM-DD')
      values.intake_details = values.courses.map((course_id) => ({
        course_id: course_id,
      }))
      if (editMode) {
        // Handle update logic here
        // eslint-disable-next-line react/prop-types
        await dispatch(updateIntake(intakeData?.id, values))
        message.success('Intake updated successfully!')
      } else {
        await dispatch(createIntake(values))
        message.success('Intake created successfully!')
      }

      setDrawerVisible(false)
      form.resetFields()
    } catch (error) {
      message.error('Failed to create intake. Please try again.')
    }
  }

  return (
    <AddIntakeForm
      form={form}
      onSubmit={handleSubmit}
      setDrawerVisible={setDrawerVisible}
      campuses={campuses}
      courses={courses}
    />
  )
}

export default AddIntakePage
