import {
  FETCH_INTAKES_REQUEST,
  FETCH_INTAKES_SUCCESS,
  FETCH_INTAKES_FAILURE,
  CREATE_INTAKE_REQUEST,
  CREATE_INTAKE_SUCCESS,
  CREATE_INTAKE_FAILURE,
  UPDATE_INTAKE_REQUEST,
  UPDATE_INTAKE_SUCCESS,
  UPDATE_INTAKE_FAILURE,
  DELETE_INTAKE_REQUEST,
  DELETE_INTAKE_SUCCESS,
  DELETE_INTAKE_FAILURE,
  FETCH_INTAKE_DETAILS_REQUEST,
  FETCH_INTAKE_DETAILS_SUCCESS,
  FETCH_INTAKE_DETAILS_FAILURE,
  ADD_STUDENT_TO_INTAKE_REQUEST,
  ADD_STUDENT_TO_INTAKE_SUCCESS,
  ADD_STUDENT_TO_INTAKE_FAILURE,
  ADD_STAFF_TO_INTAKE_REQUEST,
  ADD_STAFF_TO_INTAKE_SUCCESS,
  ADD_STAFF_TO_INTAKE_FAILURE,
  FETCH_STUDENT_INTAKES_REQUEST,
  FETCH_STUDENT_INTAKES_SUCCESS,
  FETCH_STUDENT_INTAKES_FAILURE,
} from './intakes.actions'

const initialState = {
  intakes: [],
  loading: false,
  intakeDetails: null,
  error: null,
}

const intakesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INTAKES_REQUEST:
    case CREATE_INTAKE_REQUEST:
    case UPDATE_INTAKE_REQUEST:
    case DELETE_INTAKE_REQUEST:
    case ADD_STUDENT_TO_INTAKE_REQUEST:
    case ADD_STAFF_TO_INTAKE_REQUEST:
    case FETCH_STUDENT_INTAKES_REQUEST: // Handle loading for fetching student intakes
      return {
        ...state,
        loading: true,
      }

    case FETCH_INTAKES_SUCCESS:
      return {
        ...state,
        loading: false,
        intakes: action.payload,
        error: null,
      }

    case CREATE_INTAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        intakes: [...state.intakes, action.payload],
      }

    case UPDATE_INTAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        intakes: state.intakes.map((intake) =>
          intake.id === action.payload.id ? action.payload : intake,
        ),
      }

    case DELETE_INTAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        intakes: state.intakes.filter((intake) => intake.id !== action.payload),
      }

    case FETCH_INTAKE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case FETCH_INTAKE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        intakeDetails: action.payload,
        error: null,
      }

    case ADD_STUDENT_TO_INTAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        intakeDetails: {
          ...state.intakeDetails,
          students: action.payload,
        },
      }

    case ADD_STAFF_TO_INTAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        intakeDetails: {
          ...state.intakeDetails,
          staffs: action.payload,
        },
      }

    // Handle fetching student intakes success
    case FETCH_STUDENT_INTAKES_SUCCESS:
      return {
        ...state,
        loading: false,
        intakes: action.payload,
        error: null,
      }

    // Handle all failures
    case FETCH_INTAKE_DETAILS_FAILURE:
    case FETCH_INTAKES_FAILURE:
    case CREATE_INTAKE_FAILURE:
    case UPDATE_INTAKE_FAILURE:
    case DELETE_INTAKE_FAILURE:
    case ADD_STUDENT_TO_INTAKE_FAILURE:
    case ADD_STAFF_TO_INTAKE_FAILURE:
    case FETCH_STUDENT_INTAKES_FAILURE: // Handle failure for student intakes
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default intakesReducer
