import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Spin, Alert, List, Collapse, Button, Drawer } from 'antd'
import { fetchIntakeDetails } from './intakes.api'
import AddStudentForm from './Form/AddStudentForm'
import AddStaffForm from './Form/AddStaffForm'

const { Panel } = Collapse

const IntakeDetails = () => {
  const { intakeId } = useParams()
  const dispatch = useDispatch()
  const { intakeDetails, loading, error } = useSelector(
    (state) => state.intakes,
  )
  const studentId = localStorage.getItem('studentId')
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [formType, setFormType] = useState(null) // 'student' or 'staff'

  // Function to refresh intake details after adding a student or staff
  const refreshIntakeDetails = () => {
    if (intakeId) {
      dispatch(fetchIntakeDetails(intakeId))
    }
  }

  useEffect(() => {
    refreshIntakeDetails() // Fetch intake details on component mount
  }, [dispatch, intakeId])

  const showDrawer = (type) => {
    setFormType(type)
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
  }

  if (loading) {
    return <Spin tip="Loading intake details..." />
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Intake Details</h2>
        {!studentId && (
          <div>
            <Button
              style={{ backgroundColor: '#005d41', color: 'white' }}
              type="primary"
              onClick={() => showDrawer('student')}
            >
              Add Student
            </Button>
            <Button
              type="primary"
              onClick={() => showDrawer('staff')}
              style={{
                marginLeft: '10px',
                backgroundColor: '#005d41',
                color: 'white',
              }}
            >
              Add Staff
            </Button>
          </div>
        )}
      </div>
      {intakeDetails && (
        <div>
          <p>
            <b>Name:</b> {intakeDetails.name}
          </p>
          <p>
            <b>Description:</b> {intakeDetails.description}
          </p>
          <p>
            <b>Campus:</b> {intakeDetails.campus?.name}
          </p>
          <p>
            <b>Status:</b> {intakeDetails.status}
          </p>
          <p>
            <b>Capacity:</b> {intakeDetails.capacity}
          </p>
          <Collapse defaultActiveKey={['1']} accordion>
            <Panel header="Courses" key="1">
              <List
                itemLayout="horizontal"
                dataSource={intakeDetails.courses}
                renderItem={(course) => (
                  <List.Item>
                    <List.Item.Meta
                      title={course.name}
                      description={course.description}
                    />
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header="Staffs" key="3">
              <List
                itemLayout="horizontal"
                dataSource={intakeDetails.staffs}
                renderItem={(staff) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${staff.first_name} ${staff.last_name}`}
                      description={staff.email}
                    />
                    <p>
                      <b>Courses:</b>
                    </p>
                    <List
                      size="small"
                      dataSource={staff.intakes[0].courses}
                      renderItem={(course) => (
                        <List.Item>{course.name}</List.Item>
                      )}
                    />
                  </List.Item>
                )}
              />
            </Panel>
            {!studentId && (
              <Panel header="Students" key="4">
                <List
                  itemLayout="horizontal"
                  dataSource={intakeDetails.students}
                  renderItem={(student) => (
                    <List.Item>
                      <List.Item.Meta
                        title={`${student.first_name} ${student.last_name}`}
                        description={student.email}
                      />
                      <p>
                        <b>Courses:</b>
                      </p>
                      <List
                        size="small"
                        dataSource={student.intakes[0].courses}
                        renderItem={(course) => (
                          <List.Item>{course.name}</List.Item>
                        )}
                      />
                    </List.Item>
                  )}
                />
              </Panel>
            )}
          </Collapse>
        </div>
      )}
      <Drawer
        title={`Add ${formType === 'student' ? 'Student' : 'Staff'}`}
        width={400}
        onClose={onClose}
        visible={drawerVisible}
      >
        {formType === 'student' ? (
          <AddStudentForm
            intakeId={intakeId}
            intakeDetails={intakeDetails}
            onClose={onClose}
            refreshIntakeDetails={refreshIntakeDetails} // Pass refresh function to AddStudentForm
          />
        ) : (
          <AddStaffForm
            intakeId={intakeId}
            intakeDetails={intakeDetails}
            onClose={onClose}
            refreshIntakeDetails={refreshIntakeDetails} // Pass refresh function to AddStaffForm
          />
        )}
      </Drawer>
    </div>
  )
}

export default IntakeDetails
