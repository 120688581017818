import React, { useMemo } from 'react'
import { Form, Input, Button, Select, DatePicker, Radio } from 'antd'
import countryList from 'react-select-country-list'
import PropTypes from 'prop-types'

const { Option } = Select

// Constants for password validation
const PASSWORD_MIN_LENGTH = 8
const PASSWORD_COMPLEXITY_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/

const AddStudentForm = ({ onSubmit, form }) => {
  // Country setup using react-select-country-list
  const countryOptions = useMemo(() => countryList().getData(), [])

  // Ethnicity options based on your provided list
  const ethnicityOptions = [
    { label: 'African Americans', value: 'african_americans' },
    { label: 'American Indian group', value: 'american_indian_group' },
    { label: 'Pacific Islander', value: 'pacific_islander' },
    { label: 'Alaska Natives', value: 'alaska_natives' },
    { label: 'Americans', value: 'americans' },
    { label: 'Asian people', value: 'asian_people' },
    { label: 'Native Hawaiians', value: 'native_hawaiians' },
    { label: 'White people', value: 'white_people' },
    { label: 'Latino', value: 'latino' },
    {
      label: 'Hispanic and Latino Americans',
      value: 'hispanic_latino_americans',
    },
    { label: 'Puerto Ricans', value: 'puerto_ricans' },
    { label: 'Black people', value: 'black_people' },
    { label: 'Mexicans', value: 'mexicans' },
    { label: 'Multiracial people', value: 'multiracial_people' },
    { label: 'Hispanic', value: 'hispanic' },
    { label: 'Non-Hispanic whites', value: 'non_hispanic_whites' },
    { label: 'Jewish people', value: 'jewish_people' },
    { label: 'Asian Americans', value: 'asian_americans' },
    { label: 'Multiracial Americans', value: 'multiracial_americans' },
    { label: 'Mexican Americans', value: 'mexican_americans' },
    { label: 'Romani people', value: 'romani_people' },
    { label: 'Irish people', value: 'irish_people' },
    { label: 'Chicano', value: 'chicano' },
    { label: 'Indigenous peoples of the America', value: 'indigenous_america' },
    { label: 'Han Chinese', value: 'han_chinese' },
    { label: 'Irish Travellers', value: 'irish_travellers' },
    { label: 'Palestinians', value: 'palestinians' },
    { label: 'Indian Americans', value: 'indian_americans' },
    { label: 'Spaniards', value: 'spaniards' },
    { label: 'British Asian', value: 'british_asian' },
    {
      label: 'Pacific Islander Americans',
      value: 'pacific_islander_americans',
    },
    { label: 'White British', value: 'white_british' },
    { label: 'Kurds', value: 'kurds' },
    { label: 'Berbers', value: 'berbers' },
    { label: 'French Canadians', value: 'french_canadians' },
    {
      label: 'Asian Hispanic and Latino Americans',
      value: 'asian_hispanic_latino_americans',
    },
    { label: 'Chinese Filipino', value: 'chinese_filipino' },
    { label: 'Scottish people', value: 'scottish_people' },
    { label: 'Cajuns', value: 'cajuns' },
    { label: 'Mestizo', value: 'mestizo' },
    { label: 'Inuit', value: 'inuit' },
    { label: 'Cuban Americans', value: 'cuban_americans' },
    {
      label: 'Black Hispanic and Latino Americans',
      value: 'black_hispanic_latino_americans',
    },
    { label: 'Caucasian race', value: 'caucasian_race' },
    { label: 'Koreans', value: 'koreans' },
    { label: 'Chamorro people', value: 'chamorro_people' },
    { label: 'Cubans', value: 'cubans' },
    { label: 'Arabs', value: 'arabs' },
    { label: 'Armenians', value: 'armenians' },
    { label: 'Black British people', value: 'black_british_people' },
    { label: 'Hmong people', value: 'hmong_people' },
  ]

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="student_title" label="Title">
        <Select placeholder="Select Title">
          <Option value="Mr">Mr</Option>
          <Option value="Mrs">Mrs</Option>
          <Option value="Miss">Miss</Option>
          <Option value="Dr">Dr</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="first_name"
        label="First Name"
        rules={[{ required: true, message: 'Please enter first name' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>

      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[{ required: true, message: 'Please enter last name' }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please enter a password' },
          {
            min: PASSWORD_MIN_LENGTH,
            message: `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`,
          },
          {
            pattern: PASSWORD_COMPLEXITY_REGEX,
            message:
              'Password must contain at least one lowercase letter, one uppercase letter, and one digit',
          },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="dob"
        label="Date of Birth"
        rules={[{ required: true, message: 'Please select date of birth' }]}
      >
        <DatePicker
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
          placeholder="Select Date of Birth"
          disabledTime={() => true} // Ensure time picker is disabled
        />
      </Form.Item>

      <Form.Item name="gender" label="Gender">
        <Radio.Group>
          <Radio value="MALE">Male</Radio>
          <Radio value="FEMALE">Female</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="phone" label="Phone">
        <Input placeholder="Phone Number" />
      </Form.Item>

      <Form.Item name="address_line1" label="Address Line 1">
        <Input placeholder="Address Line 1" />
      </Form.Item>

      <Form.Item name="address_line2" label="Address Line 2">
        <Input placeholder="Address Line 2" />
      </Form.Item>

      <Form.Item name="postal_address" label="Postal Address">
        <Input placeholder="Postal Address" />
      </Form.Item>

      <Form.Item name="education" label="Education">
        <Input.TextArea placeholder="Education details" />
      </Form.Item>

      <Form.Item name="nationality" label="Nationality">
        <Select
          placeholder="Select Nationality"
          options={countryOptions}
          showSearch
        />
      </Form.Item>

      <Form.Item name="birth_country" label="Country of Birth">
        <Select
          placeholder="Select Birth Country"
          options={countryOptions}
          showSearch
        />
      </Form.Item>

      <Form.Item name="ethnicity" label="Ethnicity">
        <Select placeholder="Select Ethnicity" options={ethnicityOptions} />
      </Form.Item>

      <Form.Item name="city" label="City">
        <Input placeholder="City" />
      </Form.Item>

      <Form.Item name="postcode" label="Postcode">
        <Input placeholder="Postcode" />
      </Form.Item>

      <Form.Item name="passport_id" label="Passport ID">
        <Input placeholder="Passport ID" />
      </Form.Item>

      <Form.Item name="marital_status" label="Marital Status">
        <Select placeholder="Select Marital Status">
          <Option value="MARRIED">Married</Option>
          <Option value="UNMARRIED">Unmarried</Option>
          <Option value="PREFERNOTTOSAY">Prefer not to say</Option>
          <Option value="NOTKNOWN">Not Known</Option>
        </Select>
      </Form.Item>

      <Form.Item name="preferred_contact" label="Preferred Contact">
        <Select placeholder="Select Preferred Contact">
          <Option value="ALL">All</Option>
          <Option value="EMAIL">Email</Option>
          <Option value="TELEPHONE">Telephone</Option>
          <Option value="TEXT">Text</Option>
        </Select>
      </Form.Item>

      <Form.Item name="special_need_required" label="Special Need Required">
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="special_need_details" label="Special Need Details">
        <Input.TextArea placeholder="Details of any special needs" />
      </Form.Item>

      <Form.Item name="next_of_kin" label="Next of Kin">
        <Input placeholder="Next of Kin" />
      </Form.Item>

      <Form.Item>
        <Button
          style={{
            backgroundColor: '#005d41',
            color: 'white',
          }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

AddStudentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
}

export default AddStudentForm
