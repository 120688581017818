import React from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Title } = Typography

// eslint-disable-next-line react/prop-types
const Register = ({ register }) => {
  const navigate = useNavigate()

  const onFinish = (values) => {
    register(values)
      .then((response) => {
        console.log('Registration successful:', response)
        navigate('/login')
      })
      .catch((error) => {
        console.error('Registration failed:', error)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          maxWidth: 800,
          padding: 100,
          marginTop: 100,
          border: '1px solid #e0e0e0',
          borderRadius: 8,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
        }}
      >
        <Title level={4} style={{ marginBottom: 16 }}>
          Welcome To Virtual Learning Environment
        </Title>
        <Title level={3} style={{ marginBottom: 16 }}>
          Register
        </Title>
        <Form name="register_form" onFinish={onFinish}>
          <Form.Item
            name="firstName"
            rules={[
              { required: true, message: 'Please enter your first name!' },
            ]}
          >
            <Input
              style={{ padding: 15 }}
              prefix={<UserOutlined />}
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              { required: true, message: 'Please enter your last name!' },
            ]}
          >
            <Input
              style={{ padding: 15 }}
              prefix={<UserOutlined />}
              placeholder="Last Name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
                type: 'email',
              },
            ]}
          >
            <Input
              style={{ padding: 15 }}
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password
              style={{ padding: 15 }}
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ backgroundColor: '#005d41', color: 'white' }}
              type="primary"
              htmlType="submit"
              block
            >
              <b>Register</b>
            </Button>
          </Form.Item>
        </Form>
        <b>Already have an account?</b> <a href="/login">Login here</a>
      </div>
    </div>
  )
}

export default Register
