import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchIntakesRequest,
  fetchIntakesSuccess,
  fetchIntakesFailure,
  createIntakeRequest,
  createIntakeSuccess,
  createIntakeFailure,
  updateIntakeRequest,
  updateIntakeSuccess,
  updateIntakeFailure,
  deleteIntakeRequest,
  deleteIntakeSuccess,
  deleteIntakeFailure,
  fetchIntakeDetailsRequest,
  fetchIntakeDetailsSuccess,
  fetchIntakeDetailsFailure,
  addStudentToIntakeRequest,
  addStudentToIntakeSuccess,
  addStudentToIntakeFailure,
  addStaffToIntakeRequest,
  addStaffToIntakeFailure,
  addStaffToIntakeSuccess,
  fetchStudentIntakesRequest,
  fetchStudentIntakesSuccess,
  fetchStudentIntakesFailure,
} from './intakes.actions'

// Function to handle fetching intakes
export const fetchIntakes = () => {
  return (dispatch) => {
    dispatch(fetchIntakesRequest())
    return request({
      method: 'GET',
      url: '/intakes/',
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchIntakesSuccess(response.data))
        } else if (response?.status === 204) {
          dispatch(fetchIntakesSuccess([]))
        } else {
          dispatch(fetchIntakesFailure('Fetching intakes failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Intakes Failed',
            description: 'Fetching intakes failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchIntakesFailure('An error occurred while fetching intakes'),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Intakes Error',
          description: `An error occurred while fetching intakes: ${error.message}`,
        })
      })
  }
}

// Function to handle creating a intake
export const createIntake = (intakeData) => {
  return (dispatch) => {
    dispatch(createIntakeRequest(intakeData))
    return request({
      method: 'POST',
      url: '/intakes/',
      data: intakeData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createIntakeSuccess(response.data))
        } else {
          dispatch(createIntakeFailure('Creating intake failed'))
          openNotification({
            type: 'error',
            title: 'Creating Intake Failed',
            description: 'Creating intake failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createIntakeFailure('An error occurred while creating the intake'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Intake Error',
          description: `An error occurred while creating the intake: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a intake
export const updateIntake = (intakeId, intakeData) => {
  return (dispatch) => {
    console.log('intakeData', intakeData)
    dispatch(updateIntakeRequest(intakeData))
    return request({
      method: 'PUT',
      url: `/intakes/${intakeId}`,
      data: intakeData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateIntakeSuccess(response.data))
        } else {
          dispatch(updateIntakeFailure('Updating intake failed'))
          openNotification({
            type: 'error',
            title: 'Updating Intake Failed',
            description: 'Updating intake failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateIntakeFailure('An error occurred while updating the intake'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Intake Error',
          description: `An error occurred while updating the intake: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a intake
export const deleteIntake = (intakeId) => {
  return (dispatch) => {
    dispatch(deleteIntakeRequest(intakeId))
    return request({
      method: 'DELETE',
      url: `/intakes/${intakeId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteIntakeSuccess(intakeId))
        } else {
          dispatch(deleteIntakeFailure('Deleting intake failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Intake Failed',
            description: 'Deleting intake failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteIntakeFailure('An error occurred while deleting the intake'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Intake Error',
          description: `An error occurred while deleting the intake: ${error.message}`,
        })
      })
  }
}

// Function to handle fetching intake details
export const fetchIntakeDetails = (intakeId) => {
  return (dispatch) => {
    dispatch(fetchIntakeDetailsRequest())
    return request({
      method: 'GET',
      url: `/intakes/${intakeId}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchIntakeDetailsSuccess(response.data))
        } else {
          dispatch(fetchIntakeDetailsFailure('Fetching intake details failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Intake Details Failed',
            description: 'Fetching intake details failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchIntakeDetailsFailure(
            'An error occurred while fetching intake details',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Intake Details Error',
          description: `An error occurred while fetching intake details: ${error.message}`,
        })
      })
  }
}

// Function to handle adding a student to an intake and assigning courses
export const addStudentToIntake = (intakeId, studentId, courseIds) => {
  return (dispatch) => {
    const data = { student_id: studentId, course_ids: courseIds }
    dispatch(addStudentToIntakeRequest(data))

    return request({
      method: 'POST',
      url: `/intakes/${intakeId}/add_student/`,
      data: data,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(addStudentToIntakeSuccess(response.data?.students))
          openNotification({
            type: 'success',
            title: 'Student Added to Intake',
            description:
              'Student was successfully added to the intake and courses assigned.',
          })
        } else {
          dispatch(addStudentToIntakeFailure('Adding student to intake failed'))
          openNotification({
            type: 'error',
            title: 'Adding Student to Intake Failed',
            description: 'Adding student to intake failed.',
          })
        }
      })
      .catch((error) => {
        dispatch(
          addStudentToIntakeFailure(
            'An error occurred while adding student to intake',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Adding Student to Intake Error',
          description: `An error occurred while adding student to intake: ${error.message}`,
        })
      })
  }
}

export const addStaffToIntake = (intakeId, staffId, courseIds) => {
  return (dispatch) => {
    const data = { staff_id: staffId, course_ids: courseIds }
    dispatch(addStaffToIntakeRequest(data))

    return request({
      method: 'POST',
      url: `/intakes/${intakeId}/add_staff`,
      data: data,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(addStaffToIntakeSuccess(response.data?.staffs))
          openNotification({
            type: 'success',
            title: 'Staff Added to Intake',
            description:
              'Staff was successfully added to the intake and courses assigned.',
          })
        } else {
          dispatch(addStaffToIntakeFailure('Adding staff to intake failed'))
          openNotification({
            type: 'error',
            title: 'Adding Staff to Intake Failed',
            description: 'Adding staff to intake failed.',
          })
        }
      })
      .catch((error) => {
        dispatch(
          addStaffToIntakeFailure(
            'An error occurred while adding staff to intake',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Adding Staff to Intake Error',
          description: `An error occurred while adding staff to intake: ${error.message}`,
        })
      })
  }
}

export const fetchStudentIntakes = (studentId) => {
  return (dispatch) => {
    dispatch(fetchStudentIntakesRequest())
    return request({
      method: 'GET',
      url: `/intakes/?student_id=${studentId}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchStudentIntakesSuccess(response.data))
        } else if (response?.status === 204) {
          dispatch(fetchStudentIntakesSuccess([]))
        } else {
          dispatch(
            fetchStudentIntakesFailure('Fetching student intakes failed'),
          )
          openNotification({
            type: 'error',
            title: 'Fetching Student Intakes Failed',
            description: 'Fetching student intakes failed.',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchStudentIntakesFailure(
            'An error occurred while fetching student intakes',
          ),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Student Intakes Error',
          description: `An error occurred while fetching student intakes: ${error.message}`,
        })
      })
  }
}
