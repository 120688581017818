import request from '../../core/request'
import { openNotification } from '../../utils/Notification'
import {
  fetchCampusesRequest,
  fetchCampusesSuccess,
  fetchCampusesFailure,
  createCampusRequest,
  createCampusSuccess,
  createCampusFailure,
  updateCampusRequest,
  updateCampusSuccess,
  updateCampusFailure,
  deleteCampusRequest,
  deleteCampusSuccess,
  deleteCampusFailure,
} from './campuses.actions'

// Function to handle fetching campuses
export const fetchCampuses = () => {
  return (dispatch) => {
    dispatch(fetchCampusesRequest())
    return request({
      method: 'GET',
      url: '/campuses/',
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchCampusesSuccess(response.data))
        } else if (response.status === 204) {
          dispatch(fetchCampusesSuccess([]))
        } else {
          dispatch(fetchCampusesFailure('Fetching campuses failed'))
          openNotification({
            type: 'error',
            title: 'Fetching Campuses Failed',
            description: 'Fetching campuses failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          fetchCampusesFailure('An error occurred while fetching campuses'),
        )
        openNotification({
          type: 'error',
          title: 'Fetching Campuses Error',
          description: `An error occurred while fetching campuses: ${error.message}`,
        })
      })
  }
}

// Function to handle creating a campus
export const createCampus = (campusData) => {
  return (dispatch) => {
    dispatch(createCampusRequest(campusData))
    return request({
      method: 'POST',
      url: '/campuses/',
      data: campusData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createCampusSuccess(response.data))
        } else {
          dispatch(createCampusFailure('Creating campus failed'))
          openNotification({
            type: 'error',
            title: 'Creating Campus Failed',
            description: 'Creating campus failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          createCampusFailure('An error occurred while creating the campus'),
        )
        openNotification({
          type: 'error',
          title: 'Creating Campus Error',
          description: `An error occurred while creating the campus: ${error.message}`,
        })
      })
  }
}

// Function to handle updating a campus
export const updateCampus = (campusData) => {
  return (dispatch) => {
    dispatch(updateCampusRequest(campusData))
    return request({
      method: 'PUT',
      url: `/campuses/${campusData.id}`,
      data: campusData.values,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateCampusSuccess(response.data))
        } else {
          dispatch(updateCampusFailure('Updating campus failed'))
          openNotification({
            type: 'error',
            title: 'Updating Campus Failed',
            description: 'Updating campus failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          updateCampusFailure('An error occurred while updating the campus'),
        )
        openNotification({
          type: 'error',
          title: 'Updating Campus Error',
          description: `An error occurred while updating the campus: ${error.message}`,
        })
      })
  }
}

// Function to handle deleting a campus
export const deleteCampus = (campusId) => {
  return (dispatch) => {
    dispatch(deleteCampusRequest(campusId))
    return request({
      method: 'DELETE',
      url: `/campuses/${campusId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteCampusSuccess(campusId))
        } else {
          dispatch(deleteCampusFailure('Deleting campus failed'))
          openNotification({
            type: 'error',
            title: 'Deleting Campus Failed',
            description: 'Deleting campus failed',
          })
        }
      })
      .catch((error) => {
        dispatch(
          deleteCampusFailure('An error occurred while deleting the campus'),
        )
        openNotification({
          type: 'error',
          title: 'Deleting Campus Error',
          description: `An error occurred while deleting the campus: ${error.message}`,
        })
      })
  }
}
